import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {UserService} from '@process-manager/pm-library';
import {fromEvent, merge, Observable, of, Subject} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HistoryEvent, HistoryEventBlock} from '../model/history-events';
import {startPolling} from '../utils/longPollWithRetry';
import {DateTranslateService} from './date-translate.service';

export interface HistoryLoaderResponse {
  result: HistoryEventBlock[];
  hasMoreAfter: boolean | null;
  hasMoreBefore: boolean | null;
}

@Injectable({
  providedIn: 'root'
})
export class HistoryLoaderService implements OnDestroy {
  private static readonly POLL_INTERVAL = 60_000;

  private stopPolling$ = new Subject<void>();
  private forceReload$ = new Subject<void>()
  private user$ = this.userService.user$;

  private userLoggedOut$ = this.user$.pipe(map(user => !user));
  private historyResults$: Observable<HistoryLoaderResponse> = this.user$.pipe(switchMap(user => {
    if (!!user) {
      return startPolling(() => this.loadHistory(), {
        requestInterval: HistoryLoaderService.POLL_INTERVAL,
        forceStop: this.stopPolling$,
        forceReload: this.forceReload$,
        pause: merge(this.userLoggedOut$, fromEvent(document, 'visibilitychange').pipe(map(() => document.hidden), startWith(document.hidden)))
      });
    } else {
      return of(null);
    }
  }))

  constructor(private http: HttpClient, private userService: UserService,
    private dateTranslateService: DateTranslateService) {
  }

  get eventBlocks(): Observable<HistoryEventBlock[]> {
    return this.historyResults$.pipe(map(response => response?.result || null));
  }

  convertEntry = (entry: any): HistoryEventBlock => {
    return {
      commitId: entry.commitId,
      user: entry.user,
      datetime: this.dateTranslateService.transform(new Date(entry.datetime)),
      events: entry.events as HistoryEvent[]
    }
  }

  forceReload(): void {
    this.forceReload$.next();
  }

  ngOnDestroy(): void {
    this.stopPolling$.next();
  }

  public loadHistory(all: boolean = false, cursorId: number = 0,
    previous: boolean = false): Observable<HistoryLoaderResponse> {
    return this.http.get(environment.api + this.userService.domain + '/history/', {
      params: {
        all,
        cursorId,
        previous
      }
    }).pipe(map((response: any) => {
      const convertedMap: any[] = response.result.map(this.convertEntry);
      if (!all) {
        convertedMap.reverse()
      }
      return {
        result: convertedMap,
        hasMoreBefore: response.hasMoreBefore ?? null,
        hasMoreAfter: response.hasMoreAfter ?? null
      };
    }));
  }
}
